<template>
    <div class="card">
        <div class="bg-blue-light p-2">
            <TitleButton
                :showBtn="false"
                btnTitle="Add New"
                :title="isDailyCashSummary ? 'Today\'s Cash & Bank Position' : 'Cash & Bank Register'"
            />
            <div class="row match-height" v-if="!isDailyCashSummary">
                <div class="col-12 mt-2">
                    <DateQuerySetter
                        @onClickGo="getDateWiseCashAndBank"
                    />
                </div>
            </div>
        </div>

        <div class="mt-2 px-2">
            <CashAndBankTableShort
                :tableData="cashBank"
                v-if="isDailyCashSummary"
            />

            <CashAndBankTable
                v-else
                :tableData="cashBank"
            />
        </div>
        <GlobalLoader />
    </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useRoute} from 'vue-router'
import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import CashAndBankTable from '@/components/molecule/company/report/CashAndBankTable.vue'
import CashAndBankTableShort from '@/components/molecule/company/report/CashAndBankTableShort.vue'
import useReport from '@/services/modules/dashboard/report.js'
import { computed } from 'vue';

const props = defineProps({
    onLoadData: {
        type: Function,
        default: () => {}
    }
})

const emit = defineEmits(['onLoadData']);

const {cashBank, fetchCashBank} = useReport()
const route = useRoute()
const offset = ref(1000)

const isDailyCashSummary = computed(() => route.name === "daily-cash-summary")

onMounted(() => {
    fetchCashBank(getQuery()).then((res) => {
        emit('onLoadData', {data: cashBank.value, loadType: 'cashBank'})
    })
})
function getDateWiseCashAndBank () {
    fetchCashBank(getQuery())
}
function getQuery () {
    let queryStr = '?company_id=' + route.params.companyId
    queryStr += '&offset=' + offset.value
    if(isDailyCashSummary.value) queryStr += '&is_hide_date_filter=1';
    if(!route.query.start || !route.query.end) return queryStr
    return queryStr+`&cash_and_banks_date={
        "start": "${route.query.start}",
        "end": "${route.query.end}"
        }`
}

</script>
